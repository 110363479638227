import React, { useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import LocalBussinessCard from "../../containers/PostCards/LocalBussinessCard";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../Blogs/BlogOne/Breadcrumb";
import { toast } from "react-toastify";
import { getThingsToDo } from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  // const [catData, setcatData] = useState([]);
  const [selectedItem, setselectedItem] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getThingsToDo()
      .then((response) => {
        setdata(response?.data?.data);
        setselectedItem(response?.data?.data[0]);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          {/* <Breadcrumb title="Things To Do" /> */}
          {!isLoading && (
            <div
              className=" col-lg-3"
              // style={{ paddingRight: "85px", paddingLeft: "55px" }}
            >
              <div className="binduz-er-top-news-title">
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-cross"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                 THINGS TO DO
                </h3>
              </div>
              <div className="binduz-er-social-list">
                <div className="binduz-er-list">
                  {data?.map((x) => (
                    <a className="category-search">
                      <span>
                        <span
                          style={{
                            color: `${
                              selectedItem?.id === x?.id ? "#e74d58" : ""
                            }`,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setselectedItem(x);
                          }}
                        >
                          {x?.place}
                        </span>
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
          {!isLoading ? (
            <div
              className=" col-lg-6"
              style={{ paddingRight: "40px", paddingLeft: "40px" }}
            >
              <div className="binduz-er-content">
                <h3 className="binduz-er-title">
                  <div className="binduz-er-top-news-title">
                    <h3 className="binduz-er-title">{selectedItem?.place}</h3>
                  </div>
                </h3>
                <div className="binduz-er-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedItem?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "101px" }} className=" col-lg-9">
              <Loader />
            </div>
          )}
          <div className="col-lg-3">
            <SideADModule isAd={true} isClassifield={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
