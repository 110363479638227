import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getEventDetail } from "../../services/HomeServices";
import { Link, useLocation, useParams } from "react-router-dom";
import Loader from "../../containers/Loader";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import Hero from "../Partials/Headers/HeaderOne/Hero";
import { motion } from "framer-motion";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (params?.location_id) {
      getData();
    } else {
      setdata(location?.state);
    }
  }, [params, location]);

  const formatDateForEvent = (date) => {
    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedTime = date?.toLocaleTimeString("en-US", optionsTime);

    return `${formatDateToMMDDYYYY(date)} ${formattedTime}`;
  };

  const getData = async () => {
    setisLoading(true);
    try {
      const response = await getEventDetail(params?.location_id, params?.id);
      setdata(response?.data?.data);
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      {/* {data?.event?.flyer && <Hero src={data?.event?.flyer} />} */}
      {!isLoading ? (
        <div
          className="binduz-er-author-user-area"
          style={{ paddingTop: "30px" }}
        >
          <div className="container">
            <div className="row">
              <motion.div
                className="binduz-er-meta-author"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 1 }}
                transition={{ duration: 4 }}
              >
                <div className="binduz-er-top-news-title">
                  <h3 className="binduz-er-title">{data?.event?.name}</h3>
                </div>
              </motion.div>
            </div>
          </div>
          {data?.event?.image && (
            <div className="binduz-er-thumb" style={{ textAlign: "center" }}>
              <img
                src={data?.event?.image}
                alt="Event"
                className="event-image"
              />
            </div>
          )}
          <div className="row justify-content-center mt-4">
            <div className="col-lg-10">
              <div
                className="binduz-er-author-item mb-4"
                style={{
                  borderRadius: "15px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow
                }}
              >
                {data?.event?.flyer && (
                  <div className="binduz-er-thumb">
                    <img
                      src={data?.event?.flyer}
                      height={800}
                      className="event-flyer"
                      alt="Event Flyer"
                    />
                  </div>
                )}
                <div className="binduz-er-content">
                  <div className="binduz-er-meta-item">
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i>{" "}
                        {params?.location_id
                          ? formatDateForEvent(
                              new Date(data?.event?.created_at)
                            )
                          : formatDateToMMDDYYYY(new Date(data?.created_at))}
                      </span>
                    </div>
                  </div>

                  {data?.location?.name && (
                    <div className="col-md-4">
                      <p className="card-text text-muted">
                        <strong>Organized By :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {data?.location?.name}{" "}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="col-md-4">
                    <p className="card-text text-muted">
                      <strong>Date & Time :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>
                        {params?.location_id
                          ? formatDateForEvent(
                              new Date(data?.event?.created_at)
                            )
                          : formatDateToMMDDYYYY(new Date(data?.created_at))}
                      </span>
                    </p>
                  </div>
                  {data?.location?.address && (
                    <div className="col-md-4">
                      <p className="card-text text-muted">
                        <strong>Venue :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {data?.location?.address}
                        </span>
                      </p>
                    </div>
                  )}

                  <div className="binduz-er-text mt-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: params?.location_id
                          ? data?.event?.description
                          : data?.message,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "500px" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default MainSection;
