import React, { useEffect, useState } from "react";
import {  useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../../containers/Loader";
import { formatDate } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";



const MainSection = () => {
  const location = useLocation();

  const [data, setdata] = useState(location?.state);
  const [isLoading, setisLoading] = useState(false);

  const params = useParams();


  useEffect(() => {
    // getData();
     window.scrollTo({
       top: 0,
       behavior: "smooth", // Smooth scroll effect
     });
    
  }, []);





  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <motion.div
            className=" col-lg-9"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 4 }}
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">JOB DETAIL</h3>
            </div>
            <div className="binduz-er-meta-author">
              {/* <h4>Let's Nacho 2024 | ABCD School of Dance</h4> */}
            </div>
            {!isLoading ? (
              <>
                {data?.image && (
                  <div
                    style={{
                      margin: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="binduz-er-thumb col-lg-3">
                      <img src={data?.image} height={300} alt="" />
                    </div>
                  </div>
                )}
                <div className="classifield-details-text mt-5">
                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Mobile Number :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>
                        {data?.phone}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Email :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>{data?.email}</span>
                    </p>
                  </div>
                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Posted On :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>
                        {formatDate(data?.date)}
                      </span>
                    </p>
                  </div>
                  {data?.url && (
                    <div className="col-md-4">
                      <p className="card-text text-muted">
                        <strong>Web :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>{data?.url}</span>
                      </p>
                    </div>
                  )}
                </div>

                <div className="classifield-details-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                  />
                </div>

                <div className="binduz-er-title">
                  <p>
                    When you call dont forget to mention you found this ad from
                    azindia.com
                    <br />
                  </p>
                </div>
              </>
            ) : (
              <div style={{ marginTop: "101px" }}>
                <Loader />
              </div>
            )}
          </motion.div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <SideADModule isAd={false} isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;



                   