import React, { useEffect, useState } from "react";
// import ReactQuill, { Quill } from 'react-quill'
// import "react-quill/dist/quill.snow.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";

const TextEditor = ({ touched, error, setFieldValue, values }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = stateToHTML(contentState);
    return html;
  };


  useEffect(() => {
  const html = values;
  const contentBlock = htmlToDraft(html);

  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }
  }, []);

  useEffect(() => {
    setFieldValue("details", convertContentToHTML());
  }, [editorState]);

  const editorStateChange = (state) => {
    setEditorState(state);
  };
  return (
    <>
      <div>Details*</div>

      <div
      //  style={{ backgroundColor: "white", height: "450px" }}
      >
        <Editor
          editorState={editorState}
          wrapperStyle={{
            border: "1px solid #ccc",
          
          }}
          editorStyle={{
            height: "450px",
            overflow:'scroll',
            backgroundColor:'white',
            padding: "10px",
          }}
          // style={{ borderColor: "red" }}
          toolbarClassName="toolbarClassName"
          // wrapperClassName="wrapperClassName"
          // editorClassName="editorClassName"
          onEditorStateChange={editorStateChange}
        />
      </div>
      {touched && error && <span className="text-danger">{error}</span>}
    </>
  );
};

export default TextEditor;
