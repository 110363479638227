/* eslint-disable camelcase */
export const isLogin = () => {
  const token = localStorage.getItem("token");
  const is_authenticated = localStorage.getItem("is_authenticated");

  if (!token || !is_authenticated || is_authenticated === "0") {
    return false;
  }
  return true;
};

export const formatDate = (dateStr) => {
  // if (dateStr) {
  //   const [year, month, day] = dateStr?.split("-");
  //   let newDate = `${month}-${day}-${year}`;
  //   return newDate;
  // } else {
  //   return dateStr;
  // }
   const date = new Date(dateStr);

   const monthNames = [
     "Jan",
     "Feb",
     "Mar",
     "Apr",
     "May",
     "Jun",
     "Jul",
     "Aug",
     "Sep",
     "Oct",
     "Nov",
     "Dec",
   ];

   // Extract the month, day, and year from the Date object
   const month = monthNames[date.getMonth()];
   const day = date.getDate();
   const year = date.getFullYear();

   // Format the date as 'Jul 10, 2024'
   const formattedDate = `${month} ${String(day).padStart(2, "0")}, ${year}`;
   return formattedDate
};

export const dateSendToBackend = (dateStr) => {

  // const [month, day, year] = dateStr?.split("/");

  // const formattedDate = `${year}/${month}/${day}`;
  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}/${month}/${day}`;


  return formattedDate;
};

export const getDatefromBackend = (dateStr) => {
  // const [year, month, day] = dateStr?.split("-");

  // const formattedDate = `${month}/${day}/${year}`;
  const date = new Date(dateStr);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the month, day, and year from the Date object
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date as 'Jul 10, 2024'
  const formattedDate = `${month} ${String(day).padStart(2, "0")}, ${year}`;

  return formattedDate;
};

// export const formatDateToYYYYMMDD = (date) => {
//   const year = date?.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
//   const day = String(date.getDate()).padStart(2, "0");

//   const formattedDate = `${year}/${month}/${day}`;
//   return formattedDate;
// };

export const formatDateToMMDDYYYY = (date) => {
  // const year = date?.getFullYear();
  // const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  // const day = String(date.getDate()).padStart(2, "0");

  // const formattedDate = `${month}/${day}/${year}`;
  const getDate = new Date(date);

  // Define an array of month names for formatting purposes
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the month, day, and year from the Date object
  const month = monthNames[getDate.getMonth()];
  const day = getDate.getDate();
  const year = getDate.getFullYear();

  // Format the date as 'Jul 01, 2024'
  const formattedDate = `${month} ${String(day).padStart(2, "0")}, ${year}`;
  return formattedDate;
};

export function formatDateToMMYYYY(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${year}`;
}

export const imageBaseUrl = process?.env?.REACT_APP_IMAGE_BASE_URL;

export const randomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randomSecurityCode = (length = 4) => {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convertToSlug = (str) =>
  str
    ?.toLowerCase()
    ?.replace(/ /g, "-")
    ?.replace(/[^\w-]+/g, "");

// export const selectedPaymentType = (array, id) => {
//   let payment_type;
//   for (const a of array) {
//     if (Number(a?.id) === Number(id)) {
//       payment_type = a?.payment_type;
//     }
//   }
//   return payment_type;
// };

export function decodeHTMLEntities(text) {
  const entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];
  let newText;
  if (text?.length > 0) {
    for (let i = 0, max = entities?.length; i < max; ++i)
      newText = text.replace(
        new RegExp(`&${entities[i][0]};`, "g"),
        entities[i][1]
      );

    return newText;
  }
  return "";
}

export const plainString = (text) => {
  let plainString = text?.replace(/<[^>]+>/g, "");
  return plainString;
};
