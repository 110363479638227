import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import img from "../assets/images/azlogomessage.jpeg";
import { useInView } from "react-intersection-observer";
import "./index.css"
import ClassiFieldCart from "./PostCards/ClassiFieldCart";
import { plainString } from "../lib/constant";

const DoctorPost = ({ data, isLoading }) => {
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);

  const controlsRight = useAnimation();
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewRight && !hasAnimatedRight) {
      controlsRight.start("visible");
      setHasAnimatedRight(true); 
    }
  }, [controlsRight, inViewRight]);

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

   useEffect(() => {
     const intervalId = setInterval(() => {
       if (scrollRef.current) {
         const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
         if (scrollLeft + clientWidth >= scrollWidth) {
           scrollRef.current.scrollTo({ left: 0, behavior: "smooth" }); // Reset to the beginning when reaching the end
         } else {
           scrollRight();
         }
       }
     }, 3000); // Scroll every 3 seconds

     return () => clearInterval(intervalId); // Cleanup on unmount
   }, []);
 
   const scrollRef = useRef(null);

   const scrollLeft = () => {
     scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
   };

   const scrollRight = () => {
     scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
   };


  return (
    <div className="row" style={{ paddingLeft: "40px", paddingRight: "40px" }}>
      {/* <span
        onClick={() => {
          scrollLeft();
        }}
        className="prev slick-arrow"
        style={{ display: "block" }}
      >
        <i className="far fa-angle-left"></i>
      </span>
      <span
        onClick={() => {
          scrollRight();
        }}
        className="next slick-arrow"
        style={{ display: "block" }}
      >
        <i className="far fa-angle-right"></i>
      </span> */}
      {!isLoading ? (
        <motion.div className="slider-wrapper">
          <motion.div
            className="slider-container"
            ref={scrollRef}
          >
            {data.map((x, index) => (
              <motion.div
                className="custom-box"
                style={{ backgroundImage: `url(${x?.image || img})` }}
                // whileHover={{ scale: 1.05 }}
                ref={refRight}
                initial="hidden"
                animate={controlsRight}
                variants={boxVariantsRight}
                whileHover={{ scale: 1.1 }} // Scale up the image on hover
                transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
              >
                <motion.div className="custom-box-text">
                  {/* <motion.h3 whileHover={{ color: "red" }}>{x?.name}</motion.h3>
                  <p>{x?.address}</p> */}
                  <div className="classifield-text" style={{ color: "black" }}>
                    <a> {x?.name}</a>
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      textWrap: "balance",
                      marginTop: "55px",
                    }}
                    className="line-clump-3"
                  >
                    {plainString(x?.address)}
                  </p>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DoctorPost;

