import React, { useEffect } from 'react'
import Loader from '../../containers/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBussinessPaymentSuccess, getClassifieldPaymentSuccess, getDoctorPaymentSuccess, getEventPaymentSuccess } from '../../services/AuthServices';

const MainSection = () => {
   const location = useLocation();

   const navigate = useNavigate();

   useEffect(() => {
     if (location?.pathname === "/classifield/payment-fail") {
       getFailureAPIforClassifield();
     } else if (location?.pathname === "/local-bussiness/payment-fail") {
       getFailureAPIforBussiness();
     } else if (location?.pathname === "/doctor/payment-fail") {
       getFailureAPIforDoctor();
     } else if (location?.pathname === "/event/payment-fail") {
       getFailureAPIforEvent();
     }
   }, []);

   const getFailureAPIforClassifield = async () => {
     await getClassifieldPaymentSuccess(false)
       .then((response) => {
         toast("Payment Fail", { type: "error" });
         navigate("/account-detail");
       })
       .catch((error) => {
         toast("Something went wrong", { type: "error" });
       });
   };

    const getFailureAPIforEvent = async () => {
      await getEventPaymentSuccess(false)
        .then((response) => {
          toast("Payment Fail", { type: "error" });
          navigate("/account-detail");
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    };


   const getFailureAPIforBussiness = async () => {
     await getBussinessPaymentSuccess(false)
       .then((response) => {
         toast("Payment Fail", { type: "error" });
         navigate("/account-detail");
       })
       .catch((error) => {
         toast("Something went wrong", { type: "error" });
       });
   };

   const getFailureAPIforDoctor = async () => {
     await getDoctorPaymentSuccess(false)
       .then((response) => {
         toast("Payment Fail", { type: "error" });
         navigate("/account-detail");
       })
       .catch((error) => {
         toast("Something went wrong", { type: "error" });
       });
   };

  return (
    <div className="payment-failure">
      <div className="payment-failure__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#f63a3a"
          width="96px"
          height="96px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0zm5 15.59L16.59 17 12 12.41 7.41 17 6 15.59 10.59 11 6 6.41 7.41 5 12 9.59 16.59 5 18 6.41 13.41 11 18 15.59z" />
        </svg>
      </div>
      <h1 className="payment-failure__title">PAYMENT FAILED</h1>
      <p className="payment-failure__message">
        Unfortunately, your payment could not be processed. Please try again
        later.
      </p>
    </div>
  );
}

export default MainSection