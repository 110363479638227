import React from "react";
import AccountDetailLayout from "./AccountDetailLayout";
import MessageSidebar from "./MessageSidebar";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => (
  <section className={`binduz-er-trending-area `}>
    <div className="container">
      <div className="row">
        <div className="col-lg-9 col-md-12">
          <AccountDetailLayout />
        </div>
        <div className="col-lg-3 col-md-12">
          {/* <MessageSidebar />
           */}
          <SideADModule  isEvent={true} />
        </div>
      </div>
    </div>
  </section>
);

export default MainSection;
