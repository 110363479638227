import React, { useEffect, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";

import draftToHtml from "draftjs-to-html";

const TextEditor = ({ touched, error, setFieldValue, values }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = stateToHTML(contentState);
    return html;
  };


  useEffect(() => {
    
    setFieldValue("details", convertContentToHTML());
  }, [editorState]);

  const editorStateChange = (state) => {
    setEditorState(state);
  };

  return (
    <>
      <div>Details*</div>

      <div 
      // style={{ backgroundColor: "white", height: "450px" }}
      >
        <Editor
          editorState={editorState}
          wrapperStyle={{
            border: "1px solid #ccc",
          }}
          editorStyle={{
            height: "350px",
            overflow: "scroll",
            backgroundColor: "white",
            padding: "10px",
          }}
          // style={{ borderColor: "red" }}
          toolbarClassName="toolbarClassName"
          // wrapperClassName="wrapperClassName"
          // editorClassName="editorClassName"
          onEditorStateChange={editorStateChange}
        />
      </div>
      {touched && error && <span className="text-danger">{error}</span>}
    </>
  );
};

export default TextEditor;
