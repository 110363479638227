/* eslint-disable no-unused-vars */
import React from "react";

function MovieReviewCart() {
  return (
    <>
      <div className="binduz-er-sidebar-social">
        <div
          className="binduz-er-sidebar-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h4 className="binduz-er-title">MOVIE REVIEWS</h4>
          <h2 className="binduz-er-title">{`ALL REVIEWS>`}</h2>
        </div>
        <div className="binduz-er-social-list">
          <div className="binduz-er-list">
            {[0, 2, 2, 2, 2]?.map((x) => (
              <a href="#">
                <span>
                  {/* <i className="fab fa-facebook-f"></i>{" "} */}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Tanhaji (Hindi Movie) - Hindi
                  </span>
                  <p style={{ margin: "0px" }}>Posted By IANSGood</p>
                </span>
                {/* <span>Like</span> */}
              </a>
            ))}
          </div>
        </div>
        {/* <div className="binduz-er-sidebar-add mt-20">
          <h3 className="binduz-er-title">
            Build your website & <span>grow your business</span>
          </h3>
          <a className="binduz-er-main-btn" href="#">
            Purchase
          </a>
        </div> */}
      </div>
    </>
  );
}

export default MovieReviewCart;
