import React, { useState } from "react";
import Modal from "react-modal";
import "./ModalStyles.css";

Modal.setAppElement("#root");

const PopupModal = ({ title, modalIsOpen, setModalIsOpen, yesClicked }) => {
  const closeModal = () => setModalIsOpen(false);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <h5>{title}</h5>
      <div className="button-section">
        <button onClick={yesClicked} className="close-modal-button">
          Yes
        </button>
        <button onClick={closeModal} className="close-modal-button">
          No
        </button>
      </div>
    </Modal>
  );
};

export default PopupModal;
