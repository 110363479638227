import React, { useEffect, useState } from "react";
import AboutModule from "../../containers/AboutModule";
import { toast } from "react-toastify";
import { getAboutData } from "../../services/HomeServices";

const AdInformation = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getAboutData()
      .then((response) => {
        for (let a of response?.data?.data) {
          if (a?.slug === "Ads Information") {
            setdata(a?.description);
          }
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      <AboutModule data={data} isLoading={isLoading} title={"ADs INFORMATION"} />
    </>
  );
};

export default AdInformation;
