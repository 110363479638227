import React, { useEffect, useState } from "react";
import { formatDateToMMDDYYYY } from "../lib/constant";
import Loader from "./Loader";
import { Link, useNavigate } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const JobPosts = ({ data, isLoading }) => {
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);
  const navigate = useNavigate();
  const controlsLeft = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, inViewLeft]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  return (
    <div className="row" style={{ paddingLeft: "40px", paddingRight: "40px" }}>
      {!isLoading ? (
        <>
          {data?.map((x) => (
            <motion.div
              ref={refLeft}
              initial="hidden"
              animate={controlsLeft}
              variants={boxVariantsLeft}
              className="col-lg-2"
              to={{ pathname: `/jobs-detail/${x?.id}` }}
              state={x}
              onClick={() => {
                navigate(`/jobs-detail/${x?.id}`, {
                  state: x,
                });
              }}
            >
              <div className="">
                <div className="binduz-er-job-box">
                  <h7 className="binduz-er-title line-clump-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: x?.description,
                      }}
                    />{" "}
                  </h7>
                  <h6 className="mt-3">
                    Posted:- <span>{formatDateToMMDDYYYY(x?.date)}</span>
                  </h6>
                </div>
              </div>
            </motion.div>
          ))}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default JobPosts;
