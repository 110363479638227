import React, { useEffect } from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../../components/Mobile/Drawer";
import Footer from "../../components/Partials/Footers/HomeOne/Footer";
import MainSection from "./MainSection";
import HeaderOne from "../../components/Partials/Headers/HeaderOne";
import Hero from "../../components/Partials/Headers/HeaderOne/Hero";

function AboutModule({ data, isLoading,title}) {
  const navigationData = navigations;
  useEffect(() => {
    document.body.classList.add("gray-bg");
  });
  const [drawer, setDrawer] = useToggle(false);

  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      {/* <Hero /> */}

      <MainSection data={data} isLoading={isLoading} title={title}/>
      <Footer />
    </>
  );
}

export default AboutModule;
