import React, { useState } from "react";
import Modal from "react-modal";
import "./Calender.css";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const EventPopup = ({ isOpen, onClose, events }) => {
  //   if (!isOpen) return null;

  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      className="custom-event-modal"
      overlayClassName="custom-modal-overlay"
    >
      {events?.map((x) => (
        <motion.div
          style={{
            marginTop: "10px",
            color: "white",
            borderRadius: "10px",
            backgroundColor: x?.color,
            cursor: "pointer",
            padding: "13px",
          }}
          whileHover={{ scale: 1.1 }} // Scale up the image on hover
          transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
          onClick={() => {
            navigate(`/ticket-details/${x?.location_id}/${x?.event_id}`);
          }}
        >
          <strong>{x?.title}</strong>
          <br />
          <span>Location:- {x?.location?.address}</span>
          <br />
          <span>Date:- {formatDateToMMDDYYYY(x?.date)}</span>
        </motion.div>
      ))}
    </Modal>
  );
};

export default EventPopup;
