import React from "react";

const PlanCard = ({
  plan,
  price,
  description,
  selectedPlan,
  isActive,
  onPlanChange,
}) => {
  let isSelectedPlan;
  if (isActive) {
    isSelectedPlan = true;
  } else {
    isSelectedPlan = selectedPlan?.includes(plan);
  }

  const onClicked = () => {
    if (!isActive) {
      onPlanChange(plan);
    }
  };
  return (
    <div
      className={`plan-card ${isSelectedPlan ? "selected" : ""}`}
      onClick={() => onPlanChange(plan)}
    >
      <input
        type="checkbox"
        name="plan"
        value={plan}
        checked={isSelectedPlan}
        onChange={onClicked}
      />
      <div className="plan-details">
        <h3>{plan?.charAt(0).toUpperCase() + plan?.slice(1)}</h3>
        
        <p>{description}</p>
        <p className="price">{price}</p>
      </div>
    </div>
  );
};

export default PlanCard;
