import React, { useEffect, useRef, useState } from "react";
import PostCardStyleOne from "./PostCards/PostCardStyleOne";
import SliderCom from "./Helpers/SliderCom";
import { useNavigate } from "react-router-dom";
import { getMegaPhoneData } from "../services/HomeServices";
import { toast } from "react-toastify";
import { formatDate, plainString } from "../lib/constant";
// import { addPropertyControls, ControlType, motion } from "framer";
import {  motion, useAnimation } from "framer-motion";
import _ from "lodash";
import { useInView } from "react-intersection-observer";

function MegaPhoneSlider({ featurePosts, toggle, search }) {
  const [data, setdata] = useState([]);
    const [hasAnimatedRight, setHasAnimatedRight] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

   const controlsRight = useAnimation();
   const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

   useEffect(() => {
    
     if (inViewRight && !hasAnimatedRight) {
       controlsRight.start("visible");
       setHasAnimatedRight(true); // Set the state to prevent re-triggering
     }
   }, [ controlsRight, inViewRight]);



   const boxVariantsRight = {
     hidden: { opacity: 0, x: 100 },
     visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
   };


  useEffect(() => {
    getdata();
  }, [toggle, search]);


  const getdata = async () => {
    setisLoading(true);

    await getMegaPhoneData(search)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  const featureSlider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const prevHandler = () => featureSlider.current.slickPrev();
  const nextHandler = () => featureSlider.current.slickNext();

  
  return (
    <>
      {!_.isEmpty(data) && (
        <motion.div
          style={{ padding: "50px" }}
          ref={refRight}
          initial="hidden"
          animate={controlsRight}
          variants={boxVariantsRight}
        >
          <div className="binduz-er-featured-slider-item">
            <span
              onClick={() => {
                prevHandler();
              }}
              className="prev slick-arrow"
              style={{ display: "block" }}
            >
              <i className="far fa-angle-left"></i>
            </span>
            <SliderCom selector={featureSlider} settings={settings}>
              {data &&
                data?.length > 0 &&
                data?.slice(0, 3)?.map((item, index) => (
                  <div
                    key={item.id + Math.random()}
                    className="binduz-er-video-post-item "
                  >
                    <PostCardStyleOne
                      video={false}
                      datas={{
                        isAnnouncment: true,
                        image: item?.image,
                        category: item?.title,
                        id: item?.is,
                        data: item,
                        date: formatDate(item?.start_date),
                        title: plainString(item?.content),
                      }}
                    />
                  </div>
                ))}
            </SliderCom>
            <span
              onClick={() => {
                nextHandler();
              }}
              className="next slick-arrow"
              style={{ display: "block" }}
            >
              <i className="far fa-angle-right"></i>
            </span>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default MegaPhoneSlider;
