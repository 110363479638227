import React from "react";
import useToggle from "../../../../Hooks/useToggle";
import MainHeader from "./MainHeader";
import TopHeader from "./TopHeader";

const HeaderOne = ({ drawerAction }) => {
  const [show, setValue] = useToggle(false);
  return (
    <>
      {/* <Search
        className={`${show ? "open" : ""}`}
        searchAction={setValue.toggle}
      /> */}
      <TopHeader />
      <MainHeader searchAction={setValue.toggle} drawerAction={drawerAction} />
    </>
  );
};

export default HeaderOne;
