import React, { useEffect, useState } from "react";
import EventModule from "../../containers/EventModule";
import TopStories from "../../containers/TopStories";
import { getAZTimesData, getTopStoriesData } from "../../services/HomeServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function TrendingSection() {
  const [azTimesData, setazTimesData] = useState([]);
  const [topStoriesData, settopStoriesData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAZData();
    getTopStoryData();
  }, []);

  const getAZData = async () => {
    await getAZTimesData("", "", 1, 6)
      .then((response) => {
        setazTimesData(response?.data?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getTopStoryData = async () => {
    await getTopStoriesData("", "", 1, 6)
      .then((response) => {
        settopStoriesData(response?.data?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const onClickTopStories = (e) => {
    navigate(`/details/${e?.id}`, {
      state: e,
    });
  };

  const onClickTimes = (e) => {
    navigate(`/details/${e?.id}`, {
      state: { ...e, isAzTimes: true },
    });
  };

  return (
    <>
      <section
        className={`binduz-er-trending-area `}
        style={{ paddingTop: "0px", paddingBottom: "20px" }}
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-8 col-md-12">
              <div className="row mt-50">
                <div className="col-lg-12">
                  <div className="row"></div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-12">
              <TopStories
                title="TOP STORIES"
                sideTitle="VIEW ALL>"
                data={topStoriesData}
                onClick={onClickTopStories}
                onClickHeader={() => navigate("/top-stories")}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <TopStories
                title="AZINDIA TIMES"
                sideTitle="VIEW ALL>"
                data={azTimesData}
                onClick={onClickTimes}
                onClickHeader={() => navigate("/articles")}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <EventModule isAnimation={true} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrendingSection;
