import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({ home = "Home",to="", pages, title = "Title" }) {
  return (
    <div className="binduz-er-breadcrumb-area">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <div className="binduz-er-breadcrumb-box">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{home}</Link>
                  </li>
                  {pages && (
                    <li className="breadcrumb-item">
                      <Link to={to}>{pages}</Link>
                    </li>
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
