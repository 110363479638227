import React, { useEffect, useState } from "react";
import FullCalender from "./FullCalender";
import { getCatDetail, getEventCatData } from "../../services/HomeServices";
import ReactDatePicker from "react-datepicker";
import { formatDateToMMYYYY } from "../../lib/constant";
import { useNavigate } from "react-router-dom";

const MainSection = () => {
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [eventCatData, seteventCatData] = useState([]);
  const [search, setsearch] = useState("");
  const [eventDetails, seteventDetails] = useState([]);
  const [selectedEventCat, setselectedEventCat] = useState();
  const navigate = useNavigate();

  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    getCatData();
  }, []);

  useEffect(() => {
    getCatDetails();
  }, [search, selectedMonth, selectedEventCat]);

  const getCatData = async () => {
    await getEventCatData()
      .then((response) => {
        seteventCatData(response.data.data);
      })
      .catch((error) => {});
  };

  const getCatDetails = async () => {
    await getCatDetail(selectedEventCat, formatDateToMMYYYY(selectedMonth))
      .then((response) => {
        seteventDetails(response?.data?.data);
      })
      .catch((error) => {});
  };

  return (
    <div className="calender-table">
      <div
        className="binduz-er-top-news-title"
        style={{
          display: widthOfPage ? "flex" : "",
          justifyContent: "space-between",
        }}
      >
        <h3 className="binduz-er-title">
          <i
            className="far fa-tasks"
            style={{ color: "red", marginRight: "5px" }}
          ></i>
          EVENT CALENDAR
        </h3>
        <h2
          className="classifield-text"
          style={{
            height: "40px",
            // marginLeft: "25px",
            display: "flex",
            // margin-right: 43px;
            marginTop: "20px",
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={() => navigate("/event-post")}
        >{`POST AN EVENT >>`}</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          // marginTop: "30px",
          // marginBottom: "30px",
          margin: "10px",
        }}
        className="col-lg-12"
      >
        <div className="row">
          <div className="col-lg-6 ">
            <div className="binduz-er-select ">
              <select
                placeholder="Data"
                name="country"
                value={selectedEventCat}
                onChange={(e) => setselectedEventCat(e?.target?.value)}
              >
                <option value="">Select Category</option>
                {eventCatData?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-lg-6 " style={{ zIndex: "100" }}>
            <div className="date-picker">
              <ReactDatePicker
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                autoComplete="off"
                selected={selectedMonth}
                onChange={(e) => {
                  setselectedMonth(e);
                }}
                name="date"
              />
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="binduz-er-author-sidebar-search-bar col-lg-4">
              <form
                onSubmit={(e) => {
                  e?.preventDefault();
                  getCatDetails();
                }}
              >
                <div className="binduz-er-input-box" style={{ width: "270px" }}>
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setsearch(e?.target?.value)}
                    placeholder="Search from here..."
                    style={{ lineHeight: "38px" }}
                  />

                  <button type="submit" style={{ height: "40px" }}>
                    <i className="fal fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
      <FullCalender
        data={eventDetails}
        selectedMonth={formatDateToMMYYYY(selectedMonth)}
        setselectedMonth={setselectedMonth}
      />
      {/* <GoogleCalender /> */}
    </div>
  );
};

export default MainSection;
