import React from "react";

const Pagination = ({ links, setpage }) => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        {/* <li className="page-item">
          <a className="page-link" aria-label="Previous">
            <span aria-hidden="true">
              <i className="fal fa-long-arrow-left"></i>
            </span>
          </a>
        </li> */}
        {links?.map((x) => (
          <li
            className={`page-item ${x?.active && "active"} `}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              if (x?.url) {
                setpage(x?.url?.split("page=")[1]);
              }
            }}
          >
            <a className="binduz-er-page-link">
              {x?.label
                .replace(/&laquo; Previous/g, "<-")
                .replace(/Next &raquo;/g, "->")}
            </a>
          </li>
        ))}

        {/* <li className="page-item">
          <a className="binduz-er-page-link" >
            ---
          </a>
        </li> */}

        {/* <li className="page-item">
          <a className="page-link" aria-label="Next">
            <span aria-hidden="true">
              <i className="fal fa-long-arrow-right"></i>
            </span>
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Pagination;
