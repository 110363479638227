/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import MovieReviewCart from "../../containers/MovieReviewCart";
import JobPosts from "../../containers/JobPosts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDoctorData, getJobsData, getPremiumDoctorData } from "../../services/HomeServices";
import DoctorPost from "../../containers/DoctorPost";
import { ListGroup } from "react-bootstrap";
// import SliderCom from "../Helpers/SliderCom";

function FeatureSection({ featurePosts }) {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [doctorData, setdoctorData] = useState([])
  const [docLoading, setdocLoading] = useState(false)
  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    getData();
    getDocData();
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getJobsData()
      .then((response) => {
        setisLoading(false);

        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);

        toast("Something went wrong", { type: "error" });
      });
  };

   const getDocData = async () => {
     setdocLoading(true);
     
     await getPremiumDoctorData()
       .then((response) => {
         let resData = response?.data?.data;
         setdoctorData(resData);
         setdocLoading(false);
       })
       .catch((error) => {
         setdocLoading(true);
         toast("Something went wrong", { type: "error" });
       });
   };
  return (
    <>
      <section
        className="binduz-er-featured-area"
        // style={{ backgroundColor: "#0d6efd" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="binduz-er-top-news-title"
                style={{
                  display: widthOfPage ? "flex" : "",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-briefcase"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                  JOBS
                </h3>
                <h2
                  className="classifield-text"
                  style={{
                    height: "40px",
                    // marginLeft: "25px",
                    display: "flex",
                    // margin-right: 43px;
                    marginTop: "20px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/jobs")}
                >{`VIEW ALL >>`}</h2>
              </div>

              <JobPosts data={data} isLoading={isLoading} />
            </div>
            <div className="col-lg-12">
              <div
                className="binduz-er-top-news-title"
                style={{
                  display: widthOfPage ? "flex" : "",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  paddingTop:'65px',
                  justifyContent: "space-between",
                }}
              >
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-medkit"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                  DOCTORS
                </h3>
                <h2
                  className="classifield-text"
                  style={{
                    height: "40px",
                    // marginLeft: "25px",
                    display: "flex",
                    // margin-right: 43px;
                    // marginTop: "20px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/doctor")}
                >{`VIEW ALL >>`}</h2>
              </div>

              <DoctorPost
                data={[...doctorData]}
                isLoading={docLoading}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureSection;
