/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import SliderCom from "../../../Helpers/SliderCom";

function Hero({ src }) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const settings = {
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    // asNavFor: ".hero-portal-active",
  };
  const portalSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // asNavFor: ".hero-slide-active",
    dots: false,
    centerMode: false,
    arrows: false,
    centerPadding: "0",
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="hero-slide-active">
        <SliderCom
        // settings={settings}
        // asNavFor={nav2}
        // selector={(slider1) => setNav1(slider1)}
        >
          <div className="binduz-er-hero-area d-flex align-items-center">
            {!src ? (
              <video
                className="binduz-er-bg-cover"
                style={{ objectFit: "cover" }}
                autoPlay
                loop
                muted
              >
                <source
                  // src={video}
                  src={
                    "https://static.vecteezy.com/system/resources/previews/035/669/191/mp4/slow-motion-the-lights-flicker-from-the-music-stage-and-the-people-raising-their-hands-dancing-to-the-pulsating-music-of-this-music-festival-held-free-of-charge-in-the-public-park-of-asia-free-video.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <div
                className="binduz-er-bg-cover"
                style={{ backgroundImage: `url(${src})`}}
              ></div>
            )}

            {/* <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-7">
                  <div className="binduz-er-hero-news-content">
                    <div className="binduz-er-hero-meta">
                      <div className="binduz-er-meta-category">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i> 24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <div className="binduz-er-hero-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Sparks of inspiration to the new trend 2021
                        </a>
                      </h3>
                    </div>
                    <div className="binduz-er-meta-author">
                      <div className="binduz-er-author">
                        <img
                          src={
                            require(`../../../../assets/images/user-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <span>
                          By <span>Rosalina D.</span>
                        </span>
                      </div>
                      <div className="binduz-er-meta-list">
                        <ul>
                          <li>
                            <i className="fal fa-eye"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-heart"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-comments"></i> 5k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="binduz-er-hero-weather d-flex justify-content-end">
                    <div className="binduz-er-weather-item">
                      <img
                        src={
                          require(`../../../../assets/images/icon/icon-1.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5 className="binduz-er-title">Melbourne</h5>
                      <span>31°C / 25 - 32°C</span>
                    </div>
                    <div className="binduz-er-weather-item">
                      <img
                        src={
                          require(`../../../../assets/images/icon/icon-2.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5 className="binduz-er-title">New York </h5>
                      <span>31°C / 25 - 32°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="binduz-er-hero-area d-flex align-items-center">
            <div className="binduz-er-bg-cover item-2"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-7">
                  <div className="binduz-er-hero-news-content">
                    <div className="binduz-er-hero-meta">
                      <div className="binduz-er-meta-category">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i> 24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <div className="binduz-er-hero-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Honoring Asian Pacific American Heritage Month{" "}
                        </a>
                      </h3>
                    </div>
                    <div className="binduz-er-meta-author">
                      <div className="binduz-er-author">
                        <img
                          src={
                            require(`../../../../assets/images/user-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <span>
                          By <span>Rosalina D.</span>
                        </span>
                      </div>
                      <div className="binduz-er-meta-list">
                        <ul>
                          <li>
                            <i className="fal fa-eye"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-heart"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-comments"></i> 5k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="binduz-er-hero-weather d-flex justify-content-end">
                    <div className="binduz-er-weather-item">
                      <img
                        src={
                          require(`../../../../assets/images/icon/icon-1.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5 className="binduz-er-title">Melbourne</h5>
                      <span>31°C / 25 - 32°C</span>
                    </div>
                    <div className="binduz-er-weather-item">
                      <img
                        src={
                          require(`../../../../assets/images/icon/icon-2.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5 className="binduz-er-title">New York </h5>
                      <span>31°C / 25 - 32°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="binduz-er-hero-area d-flex align-items-center">
            <div className="binduz-er-bg-cover item-3"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-7">
                  <div className="binduz-er-hero-news-content">
                    <div className="binduz-er-hero-meta">
                      <div className="binduz-er-meta-category">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i> 24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <div className="binduz-er-hero-title">
                      <h3 className="binduz-er-title">
                        <a href="#">Championing women’s sports with the WNBA</a>
                      </h3>
                    </div>
                    <div className="binduz-er-meta-author">
                      <div className="binduz-er-author">
                        <img
                          src={
                            require(`../../../../assets/images/user-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <span>
                          By <span>Rosalina D.</span>
                        </span>
                      </div>
                      <div className="binduz-er-meta-list">
                        <ul>
                          <li>
                            <i className="fal fa-eye"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-heart"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-comments"></i> 5k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="binduz-er-hero-weather d-flex justify-content-end">
                    <div className="binduz-er-weather-item">
                      <img
                        src={
                          require(`../../../../assets/images/icon/icon-1.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5 className="binduz-er-title">Melbourne</h5>
                      <span>31°C / 25 - 32°C</span>
                    </div>
                    <div className="binduz-er-weather-item">
                      <img
                        src={
                          require(`../../../../assets/images/icon/icon-2.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5 className="binduz-er-title">New York </h5>
                      <span>31°C / 25 - 32°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </SliderCom>
      </div>
      {/* <div className="hero-portal-area">
        <div className="binduz-er-hero-news-portal hero-portal-active">
          <SliderCom
            asNavFor={nav1}
            selector={(slider2) => setNav2(slider2)}
            settings={portalSliderSettings}
          >
            {[2, 4, 4, 4]?.map(() => (
              <div className="binduz-er-news-portal-item">
                <div className="binduz-er-news-portal-item-wrapped">
                  <div className="binduz-er-thumb">
                    <a href="#">
                      <img
                        src={
                          require(`../../../../assets/images/event-photo.jpeg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="binduz-er-content">
                    <h7>
                      <a href="#">
                        Shankar Ehsaan Loy - Live in Phoenix June 30th <br />{" "}
                        the new trend 2021
                      </a>
                    </h7>
                    <div className="binduz-er-post-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <span>
                      Hello Phoenix! Buckle up, tickets on sale for Shankar
                      Ehsaan Loy -- Live In Phoenix. Early Bird Mother's Day
                      Sale till May 12th, so pick the best seats before they are
                      gone!
                    </span>
                    <div style={{ textAlign: "end", paddingLeft: "50px" }}>
                      <p className="classifield-text">Tickets</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SliderCom>
        </div>
      </div> */}
    </>
  );
}

export default Hero;
