import React, { useRef } from "react";
import SliderCom from "../../../Helpers/SliderCom";
import { Link, useNavigate } from "react-router-dom";
import { isLogin } from "../../../../lib/constant";

const TopHeader = () => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };
  const handlerSliderPrev = () => sliderRef.current.slickPrev();
  const handlerSliderNext = () => sliderRef.current.slickNext();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.removeItem("is_authenticated");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    
    isLogin();
    navigate("/");
    //   await logout()
    //     .then(() => {
    // localStorage.removeItem("is_authenticated");
    // localStorage.removeItem("token");
    // localStorage.removeItem("userId");

    //       setIsAuthenticated(0);

    //       navigate("/");
    //     })
    //     .catch((error) => {
    //       if (error?.response?.status == 422)
    //         toast(error.response.data.error, { type: "error" });
    //       else if (error?.response?.status == 500)
    //         toast(error.response.data.message, { type: "error" });
    //       else toast("Something went wrong", { type: "error" });
    //     });
  };
  return (
    <>
      <div className="binduz-er-top-header-area">
        <div className="binduz-er-bg-cover"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="binduz-er-topbar-headline"
                style={{ position: "relative" }}
              >
                <span
                  onClick={handlerSliderPrev}
                  className="prev slick-arrow"
                  style={{ display: "block" }}
                >
                  <i className="fas fa-angle-left"></i>
                </span>
                <SliderCom settings={settings} selector={sliderRef}>
                  <p>
                    <span>
                      <i className="fas fa-bolt"></i> Trending News:
                    </span>
                    <a href="#">
                      Miranda halim was viral after finish his workout.
                    </a>
                  </p>
                  <p>
                    <span>
                      <i className="fas fa-bolt"></i> Trending News:
                    </span>
                    <a href="#">
                      Celebrating Asian Pacific American art and cultures
                    </a>
                  </p>
                  <p>
                    <span>
                      <i className="fas fa-bolt"></i> Trending News:
                    </span>
                    <a href="#">
                      Honoring Asian Pacific American Heritage Month at Google
                    </a>
                  </p>
                </SliderCom>
                <span
                  onClick={handlerSliderNext}
                  className="next slick-arrow"
                  style={{ display: "block" }}
                >
                  <i className="fas fa-angle-right"></i>
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="binduz-er-topbar-social d-flex justify-content-end align-items-center"
                // style={{ minHeight: "52px", paddingRight: isLogin() && "40px" }}
              >
                {/* <div className="binduz-er-news-social">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
                {!isLogin() && (
                  <div className="binduz-er-news-subscribe-btn">
                    <Link className="binduz-er-main-btn" to="/login">
                      Login
                    </Link>
                  </div>
                )
                //  : (
                //   <div className="binduz-er-news-subscribe-btn">
                //     <Link
                //       className="binduz-er-main-btn"
                //       onClick={(e) => handleLogout(e)}
                //     >
                //       Logout
                //     </Link>
                //   </div>
                // )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
