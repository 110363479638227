import React, { useEffect } from "react";
import MainPostsContext from "../../contexts/HomeOne/MainPostsContext";
// import TrendingNewsContext from "../../contexts/HomeOne/TrendingNewsContext";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import HeaderOne from "../Partials/Headers/HeaderOne";
import Hero from "../Partials/Headers/HeaderOne/Hero";
import FeatureSection from "./FeatureSection";
import MainPostsSection from "./MainPostsSection";
// import TodayTrending from "./TodayTrending";
import TrendingSection from "./TrendingSection";
// import VideoPostsSection from "./VideoPostsSection";
// import SecondSection from "./SecondSection";
import TrendingNewsContext from "../../contexts/HomeOne/TrendingNewsContext";
import ClassiFields from "./ClassiFields";
import ClassiFieldsAd from "./ClassiFieldsAd";
import AD from "./AD";
import { motion } from "framer-motion";

const HomeOne = () => {
  const navigationData = navigations;

  const [drawer, setDrawer] = useToggle(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  return (
    <motion.div
    // initial={{ opacity: 0 }}
    // animate={{ opacity: 1 }}
    // exit={{ opacity: 0 }}
    style={{overflow:'hidden'}}
    >
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <Hero />
      <MainPostsContext.Provider>
        <MainPostsSection />
      </MainPostsContext.Provider>
      <AD />
      <TrendingNewsContext.Provider>
        <TrendingSection />
      </TrendingNewsContext.Provider>
      {/* <SecondSection /> */}
      <FeatureSection />
      {/* <VideoPostsSection
        category={treandingNewsCategoryTab}
        videoPosts={videoPosts}
      />
      <TodayTrending todayTrending={todayTrending} /> */}

      <ClassiFields />
      <ClassiFieldsAd />

      <Footer />
      <BackToTop />
    </motion.div>
  );
};

export default HomeOne;
