import React, { useEffect, useState } from "react";
import Pagination from "../../containers/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { getDoctorData, getDoctorList } from "../../services/HomeServices";
import { toast } from "react-toastify";
import img from "../../assets/images/azlogomessage.jpeg";

import _ from "lodash";
import Loader from "../../containers/Loader";
import { plainString } from "../../lib/constant";

const MainSection = () => {
  const [catSearch, setcatSearch] = useState("");
  const [search, setsearch] = useState("");
  const [data, setdata] = useState([]);
  const [catId, setcatId] = useState();
  const [catIsLoading, setcatIsLoading] = useState(false);
  const [doctorList, setdoctorList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [catName, setcatName] = useState("");
  const [links, setlinks] = useState([]);
  const [firstTimeCalled, setfirstTimeCalled] = useState(false);
  const navigate = useNavigate();

  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    getDoctors();
  }, [catSearch]);

  useEffect(() => {
    if (catId) {
      if (firstTimeCalled) {
        getData();
      }
    }
    return () => {};
  }, [search, page, catId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);


  const getData = async (id) => {
    setisLoading(true);
    let categoryId = id || catId;
    await getDoctorData(categoryId, search, page)
      .then((response) => {
        let resData = response?.data?.data?.data;
        setdata(resData);
        setlinks(response?.data?.data?.links);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getDoctors = async () => {
    setcatIsLoading(true);
    await getDoctorList(catSearch?.trim())
      .then((response) => {
        setcatIsLoading(false);
        setdoctorList(response?.data?.data);
        if (!firstTimeCalled) {
          setcatId(response?.data?.data[0]?.id);
          getData(response?.data?.data[0]?.id);
          setcatName(response?.data?.data[0]?.name);
        }
        setfirstTimeCalled(true);
      })
      .catch((error) => {
        setcatIsLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  // const getDoctorWhileSearching = async () => {
  //   setcatIsLoading(true);
  //   await getDoctorList(catSearch?.trim())
  //     .then((response) => {
  //       setcatIsLoading(false);
  //       setdoctorList(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       setcatIsLoading(true);
  //       toast("Something went wrong", { type: "error" });
  //     });
  // };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">CATEGORIES</h3>
            </div>
            <div
              className="binduz-er-social-list"
              // style={{ height: "700px", overflow: "scroll" }}
            >
              <div className="binduz-er-list">
                <Link
                  className="category-search"
                  style={{ marginBottom: "25px" }}
                >
                  <span>
                    <input
                      type="text"
                      value={catSearch}
                      onChange={(e) => setcatSearch(e?.target?.value)}
                      style={{ border: "none", color: "gray" }}
                      placeholder="Search Category..."
                    />
                  </span>
                  <i className="fa fa-search" onClick={() => getDoctors()}></i>{" "}
                </Link>
                {!catIsLoading ? (
                  <div 
                  style={{ height: "700px", overflow: "scroll" }}
                  >
                    {doctorList?.map((x) => (
                      <a
                        className="category-search "
                        onClick={() => {
                          setcatId(x?.id);
                          setcatName(x?.name);
                        }}
                      >
                        <span>
                          <span
                            style={{
                              cursor: "pointer",
                              color: `${catId === x?.id ? "#e74d58" : ""}`,
                            }}
                          >
                            {x?.name} - {x?.doctors_count || 0}
                          </span>
                        </span>
                      </a>
                    ))}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div
              className="binduz-er-top-news-title"
              style={{
                display: widthOfPage ? "flex" : "",
                justifyContent: "space-between",
              }}
            >
              <h3 className="binduz-er-title">
                <i
                  className="far fa-medkit"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                DOCTORS
              </h3>
              <h2
                className="classifield-text"
                style={{
                  height: "40px",
                  // marginLeft: "25px",
                  display: "flex",
                  // margin-right: 43px;
                  marginTop: "20px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => navigate("/doctor-post")}
              >{`POST DOCTOR ENQUIRY >>`}</h2>
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "end",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          value={search}
                          onChange={(e) => setsearch(e?.target?.value)}
                          type="text"
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <>
                <h2
                  className="classifield-text"
                  style={{
                    height: "40px",
                    // marginLeft: "25px",
                    display: "flex",
                    // margin-right: 43px;
                    cursor: "pointer",
                    alignItems: "center",
                    color: "white",
                    backgroundColor: "#e74d58",
                  }}
                >
                  {catName}
                </h2>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((x, index) => (
                      <div className="container mt-5">
                        <div
                          className="profile-card d-flex align-items-center p-4"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "15px",
                            marginBottom: "20px",
                          }}
                        >
                          <div className="profile-info">
                            <div className="d-flex">
                              <div
                                className={`${x?.profile_image && "col-lg-8"}`}
                              >
                                <h5>
                                  <Link>{plainString(x?.name)}</Link>
                                </h5>
                                <p>
                                  {plainString(x?.address)}
                                  <br />
                                  {x?.zip_code}
                                  <br />
                                  {x?.city?.city} {x?.state?.state}
                                </p>
                              </div>
                              {x?.profile_image && (
                                <div className="col-lg-4">
                                  <img
                                    src={x?.profile_image}
                                    alt="Profile"
                                    className="profile-image mr-4"
                                    style={{
                                      borderRadius: "5px",
                                      objectFit: "cover",
                                      border: "3px solid", // Border thickness
                                      borderImageSlice: 1,
                                      borderImageSource:
                                        "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)", // Multi-color gradient
                                      boxShadow:
                                        "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            {x?.comment && (
                              <div
                                style={{ marginTop: "20px" }}
                                dangerouslySetInnerHTML={{
                                  __html: x?.comment,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ marginTop: "80px", textAlign: "center" }}>
                    No Data Found
                  </h5>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
