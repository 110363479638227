/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import useToggle from "../../Hooks/useToggle";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import Hero from "../Partials/Headers/HeaderOne/Hero";
import HeaderOne from "../Partials/Headers/HeaderOne";
import MainSection from "./MainSection";

const FailPage = () => {
  const navigationData = navigations;
  useEffect(() => {
    document.body.classList.add("gray-bg");
  });
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      {/* <Hero /> */}
      <MainSection />
      <Footer />
    </>
  );
};

export default FailPage;
