import React, { useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideClassifieldModule from "../../containers/PostCards/SideClassifieldModule";
import {
  getClassifieldCatData,
  getClassifieldData,
  getLatestClassifieldData,
} from "../../services/HomeServices";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import "./index.css";
import { formatDate, plainString } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";

export const imgArray = [
  img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img4,
  img3,
  img,
  img3,
  img2,
  img5,
];
const categoriesEmogiArray = [
  // { name: "Pin Hopes", emoji: "📌" }, // Pin
  { name: "Training Classes", emoji: "🎓" }, // Graduation Cap
  { name: "Car Pool", emoji: "🚗" }, // Car
  { name: "Food & Catering", emoji: "🍔" }, // Burger
  { name: "Beauty Services", emoji: "💄" }, // Lipstick
  { name: "Care Takers", emoji: "👩‍⚕️" }, // Healthcare Worker
  { name: "Art/Dance/Music", emoji: "🎨" }, // Palette
  { name: "Real Estate", emoji: "🏡" }, // House
  { name: "For Sale", emoji: "💰" }, // Money Bag
  { name: "Travel", emoji: "✈️" }, // Airplane
  { name: "Rentals", emoji: "🏠" }, // House (Alternative to Real Estate)
  { name: "Automobiles", emoji: "🚘" }, // Car (Alternative to Car Pool)
];

const MainSection = () => {
  const [catData, setcatData] = useState([]);
  const [search, setsearch] = useState("");
  const [latestClassiLoading, setlatestClassiLoading] = useState(false);
  const [latestClassiData, setlatestClassiData] = useState([]);
  const [selectedCat, setselectedCat] = useState(undefined);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    getData();
    classifieldData();

    latestClassifeildData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  useEffect(() => {
    if (selectedCat) {
      classifieldData(selectedCat);
    }
  }, [search, selectedCat, page]);

  const getData = async () => {
    await getClassifieldCatData()
      .then((response) => {
        let id = params?.id || response.data.data[6]?.id;
         const array = response?.data?.data?.filter(
           (a) => a?.name !== "Pin Hopes"
         );
         setcatData(array);
        // setcatData(response?.data?.data);
        // classifieldData(id);
        // setselectedCat(id);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const classifieldData = async (id) => {
    setisLoading(true);
    await getClassifieldData(id || selectedCat, search, "", page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);

        toast("Something went wrong", { type: "error" });
      });
  };

  const latestClassifeildData = async () => {
    setlatestClassiLoading(true);
    await getLatestClassifieldData()
      .then((response) => {
        setlatestClassiLoading(false);
        setlatestClassiData(response?.data?.data);
      })
      .catch((error) => {
        setlatestClassiLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div
              className="binduz-er-top-news-title"
              style={{
                display: widthOfPage ? "flex" : "",
                justifyContent: "space-between",
              }}
            >
              <h3 className="binduz-er-title">
                <i
                  className="far fa-tasks"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                 CLASSIFIEDS
              </h3>
              <h2
                className="classifield-text"
                style={{
                  height: "40px",
                  // marginLeft: "25px",
                  display: "flex",
                  // margin-right: 43px;
                  marginTop: "20px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => navigate("/classifield-post")}
              >{`POST YOUR CLASSIFIED >>`}</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="binduz-er-select ">
                    <select
                      placeholder="Data"
                      name="country"
                      value={selectedCat}
                      onChange={(e) => setselectedCat(e?.target?.value)}
                    >
                      {catData?.map((x, i) => (
                        <option value={x?.id}>
                          {categoriesEmogiArray[i]?.emoji}
                          {x?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        classifieldData(selectedCat);
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((item, index) => (
                      <div
                        className="card movie-module"
                       
                      >
                        <div className="card-body">
                          <h6
                            className="binduz-er-title line-clump-2 card-title"
                            style={{ cursor: "pointer" }}
                            onClick={(item) => {
                              navigate(
                                `/classifield-detail/${data[index]?.id}`
                              );
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </h6>

                          <div className="row">
                            <div className="col-md-4">
                              <p className="card-text text-muted">
                                <strong>Posted On:</strong>{" "}
                                <span style={{ color: "#e74d58" }}>
                                  {formatDate(item?.start_date)}
                                </span>
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="card-text text-muted">
                                <strong>Email:</strong>{" "}
                                <span style={{ color: "#e74d58" }}>
                                  {item?.email}
                                </span>
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="card-text text-muted">
                                <strong>Mobile Number:</strong>{" "}
                                <span style={{ color: "#e74d58" }}>
                                  {item?.phone_number}
                                </span>
                              </p>
                            </div>
                          </div>
                          <p className="card-text">
                            <strong>Address:</strong>{" "}
                            <span style={{ color: "#e74d58" }}>
                              {item?.contact_detail}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center", marginTop: "70px" }}>
                    No Data found
                  </h5>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div className=" col-lg-3">
            <SideADModule isAd={false} isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
