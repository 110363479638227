/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { toast } from "react-toastify";
import {
  getbussinessCategoryList,
  getPremiumAmount,
} from "../../services/HomeServices";
import {
  addBussiness,
  getAccountData,
  getCityData,
  getStateData,
} from "../../services/AuthServices";
import { Link, useNavigate } from "react-router-dom";
import PremiumModal from "../../containers/PremiumModal";

const ClassiFieldForm = () => {
  const [catList, setcatList] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [profileData, setprofileData] = useState({});
  const [isDisable, setisDisable] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFirstTime, setisFirstTime] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    getCategory();
    getData();
    getState();
    getAmount();
  }, []);

  const getData = async () => {
    await getAccountData()
      .then((response) => {
        setprofileData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getState = async () => {
    await getStateData()
      .then((response) => {
        setstateData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getAmount = async () => {
    await getPremiumAmount()
      .then((response) => {
        const event = response?.data?.data?.find((a) => a?.type === "business");
        if (event) {
          setpremiumAmount(Number(event?.amount));
        }
      })
      .catch((error) => {});
  };
  const getCity = async (code) => {
    await getCityData(code)
      .then((response) => {
        setcityData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const handeStateChange = (stateId) => {
    setFieldValue("stateId", stateId);
    let code;
    for (let a of stateData) {
      if (a?.id == Number(stateId)) {
        code = a?.state_code;
      }
    }

    getCity(code);
  };

  const getCategory = async () => {
    await getbussinessCategoryList("")
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const AdminSchema = object().shape({
    name: string().required("Please enter name.").trim("Please enter name."),
    address: string()
      .required("Please enter Address.")
      .trim("Please enter Address."),
    // landmark: string()
    //   .required("Please enter landmark.")
    //   .trim("Please enter landmark."),
    description: string()
      .required("Please enter description.")
      .trim("Please enter description."),
    url: string().required("Please enter URL.").trim("Please enter URL."),
    categoryId: string()
      .required("Please enter category.")
      .trim("Please enter category."),
    isMember: string()
      .required("Please enter Member.")
      .trim("Please enter Member."),
    stateId: string()
      .required("Please enter State.")
      .trim("Please enter State."),
    cityId: string().required("Please enter City.").trim("Please enter City."),
    zip: string()
      .required("Please enter zip.")
      .trim("Please enter zip.")
      .matches(/^\d{5}$/, "Must be exactly 5 digits"),
  });

  const submitClicked = async (data) => {
    if (!values?.isFree || !isFirstTime) {
      setisDisable(true);
      await addBussiness(data)
        .then((response) => {
          setisDisable(false);

          if (values?.isFree || premiumAmount === 0) {
            toast("Bussiness added Successfully", { type: "success" });
            navigate("/local-bussiness");
          } else {
            response?.data?.data?.url
              ? window.location.replace(response?.data?.data?.url)
              : navigate("/local-bussiness");
          }
        })
        .catch((error) => {
          setisDisable(false);

          if (error?.response?.status == 422)
            toast(error?.response?.data?.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else
            toast(error?.response?.data?.message || "Something went wrong", {
              type: "error",
            });
        });
    } else {
      setModalIsOpen(true);
      setisFirstTime(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      name: "",
      address: "",
      url: "",
      description: "",
      categoryId: "",
      isMember: "",
      landmark: "",
      isFree: true,
      stateId: "",
      cityId: "",
      zip: "",
      amount: 0,
    },
    onSubmit: async () => {
      let formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("cate_id", values.categoryId);
      formdata.append("member", values.isMember === 0 ? false : true);
      formdata.append("type", values.isFree ? "Standard" : "Premium");
      formdata.append("description", values.description);
      formdata.append("url", values.url);
      formdata.append("address", values.address);
      formdata.append("landmark", values.landmark);
      formdata.append("postal", values.zip);
      formdata.append("state_id", values.stateId);
      formdata.append("city_id", values.cityId);
      formdata.append("amount", values.isFree ? 0 : premiumAmount);

      submitClicked(formdata);
    },

    onReset: () => {},
  });
  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <h6 className="mt-1">
            List Your Business for Free! Upgrade to Premium for Maximum
            Exposure.{" "}
            <Link
              style={{ textDecoration: "underline", color: "red" }}
              onClick={() => setModalIsOpen(true)}
            >
              Explore Premium !
            </Link>{" "}
          </h6>
          <div>
            <h7 style={{ fontWeight: "700px" }}> Local Businesses Type: </h7>
            <input
              className="m-2"
              type="radio"
              id="standard"
              onChange={(e) => setFieldValue("isFree", true)}
              checked={values?.isFree}
            />
            <span>Standard (Free for 15 days) </span>
            <input
              className="m-2"
              type="radio"
              id="premium"
              checked={!values?.isFree}
              onChange={(e) => setFieldValue("isFree", false)}
            />
            <span>Premium (${premiumAmount} for 30 days) </span>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Data"
                name="categoryId"
                onBlur={handleBlur}
                value={values?.categoryId}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.categoryId && errors?.categoryId && "red",
                }}
              >
                <option value="">Select Category</option>
                {catList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>
              {touched?.categoryId && errors?.categoryId && (
                <span className="text-danger">{errors.categoryId}</span>
              )}
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Member"
                name="isMember"
                onBlur={handleBlur}
                style={{
                  borderColor: touched?.isMember && errors?.isMember && "red",
                }}
                value={values?.isMember}
                onChange={handleChange}
              >
                <option value="">Select Member</option>
                <option value={1}>{"Member"}</option>
                <option value={0}>{"Non-Member"}</option>
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.isMember && errors?.isMember && (
              <span className="text-danger">{errors.isMember}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="State"
                name="stateId"
                id="stateId"
                value={values.stateId}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => handeStateChange(e?.target?.value)}
                style={{
                  borderColor: touched?.stateId && errors?.stateId && "red",
                }}
              >
                <option value="">Select State</option>
                {stateData?.map((x) => (
                  <option value={x?.id}>{x?.state}</option>
                ))}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.stateId && errors?.stateId && (
              <span className="text-danger">{errors.stateId}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="City"
                name="cityId"
                onBlur={handleBlur}
                style={{
                  borderColor: touched?.cityId && errors?.cityId && "red",
                }}
                value={values?.cityId}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cityData?.map((x) => (
                  <option value={x?.id}>{x?.city}</option>
                ))}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.cityId && errors?.cityId && (
              <span className="text-danger">{errors.cityId}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <input
                type="number"
                placeholder="Zip *"
                name="zip"
                id="zip"
                value={values.zip}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.zip && errors?.zip && "red",
                }}
              />
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.zip && errors?.zip && (
              <span className="text-danger">{errors.zip}</span>
            )}
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              // , paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor: touched?.address && errors?.address && "red",
                }}
                name="address"
                id="address"
                maxLength={1200}
                onBlur={handleBlur}
                value={values?.address}
                onChange={handleChange}
                cols="3"
                rows="4"
                placeholder="Address*"
              ></textarea>
              {touched?.address && errors?.address && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.address}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {1200 - values?.address?.length}
              </div>
            </div>
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              // , paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
                name="landmark"
                id="landmark"
                onBlur={handleBlur}
                value={values?.landmark}
                onChange={handleChange}
                cols="3"
                rows="4"
                placeholder="Landmark"
              ></textarea>
              {touched?.landmark && errors?.landmark && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.landmark}
                </div>
              )}
            </div>
          </div>

          <div className=" col-lg-12" style={{ textAlign: "center" }}>
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor:
                    touched?.description && errors?.description && "red",
                }}
                name="description"
                id="description"
                cols="3"
                maxLength={900}
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                rows="4"
                placeholder="Description*"
              ></textarea>
              {touched?.description && errors?.description && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.description}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {900 - values?.description?.length}
              </div>
            </div>
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Name *"
                name="name"
                id="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.name && errors?.name && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.name && errors?.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="URL *"
                name="url"
                id="url"
                value={values.url}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.url && errors?.url && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.url && errors?.url && (
              <span className="text-danger">{errors.url}</span>
            )}
          </div>
        </div>
        <button
          type="submit"
          disabled={isDisable}
          onClick={handleSubmit}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable
            ? "Loading..."
            : values?.isFree
            ? "Submit"
            : "Proceed to payment"}{" "}
        </button>
      </form>
      <PremiumModal
        yesClicked={() => {
          setFieldValue("isFree", false);
          setModalIsOpen(false);
        }}
        noClicked={() => {
          setFieldValue("isFree", true);
          setModalIsOpen(false);
        }}
        title={"LOCAL BUSSINESS"}
        amount={premiumAmount}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        topLinesArray={[
          "You can list your business on our platform for free and connect with the Indian community seeking your products and services. Your free listing will help customers find the right businesses for their needs, fostering a strong, supportive community.",
          `For just $${premiumAmount}, you can upgrade your classified and unlock these exclusive benefits:`,
        ]}
        descArray={[
          " Your business will be featured in a spotlight position, enhancing visibility and attracting more customers.",
          " Upload up to [X] images making your listing more engaging and informative.",
          " Appear at the top of search results, making it easier for customers to find your business first.",
          " By being more visible, you’ll help the Indian community find and support local businesses, fostering economic growth and community ties.",
        ]}
        titleArray={[
          "Spotlight Position:-",
          "Enhanced Media Uploads:-",
          "Priority Listing:-",
          "Community Support:-",
        ]}
        lastLine={`Upgrading to Premium ensures your business stands out, attracts more attention, and helps the Indian community connect with the services and products they need. Post for free and upgrade to Premium to maximize your impact and reach within the community.`}
      />
    </div>
  );
};

export default ClassiFieldForm;
