import React, { useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideADModule from "../../containers/PostCards/SideADModule";
import { getMegaPhoneData } from "../../services/HomeServices";
import { formatDate } from "../../lib/constant";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([])

  const navigate=useNavigate()

  useEffect(() => {
    getData();
     window.scrollTo({
       top: 0,
       behavior: "smooth", // Smooth scroll effect
     });
  }, [search,page]);

  const getData = async () => {
    setisLoading(true);
    await getMegaPhoneData(search,page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">
                <i
                  className="far fa-bullhorn"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                MEGAPHONE
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => setsearch(e?.target?.value?.trim())}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((item, index) => (
                      <CardRowModule
                        key={item.id}
                        datas={{
                          data: { ...item, isMegaphone: true },
                          image: item?.image,
                          category: item?.title,
                          index: index,
                          isTopStories: true,
                          title: item?.content,
                          pathname: `/details/${item?.id}`,
                          onClickFun: () => {
                            navigate(`/details/${item?.id}`, {
                              state: { ...item, isMegaphone: true },
                            });
                          },
                          date: formatDate(item?.start_date),
                        }}
                      />
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Data found</h5>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <SideADModule isEvent={true} isClassifield={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
