import React, { useEffect } from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import Author from "./Author";
import HeaderOne from "../Partials/Headers/HeaderOne";

function Archive() {
  const navigationData = navigations;
  useEffect(() => {
    document.body.classList.add("gray-bg");
  });
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <Author />
      <Footer />
    </>
  );
}

export default Archive;
