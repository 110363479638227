import React from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";

export const imgArray = [img, img1, img2, img3, img4, img5];
const onClick = () => {};
const CardRowModule = ({
  datas = {
    data: {},
    isJobs: false,
    image: "",
    category: "",
    title: "",
    date: "",
    pathname:'',
    isTopStories: false,
    index: "0",
    author: "",
    to: "",
    state: {},
    onClickFun: onClick,
  },
}) => {
  const navigate = useNavigate("");
  return (
    <div className="binduz-er-latest-news-item">
      <div className="binduz-er-thumb">
        <img
          // src={require(`../../assets/images/classifield.png`).default}
          src={datas?.image || img}
          alt=""
        />
      </div>
      <div
        className="binduz-er-content"
        onClick={() => {
          if (!datas?.isTopStories) {
            navigate("/classifield-detail");
          }
        }}
      >
        {datas?.category && (
          <div className="classifield-text">
            <span>{datas?.category}</span>
          </div>
        )}
        <h5 className="binduz-er-title line-clump-2">
          <Link
          to={{
            pathname: datas?.pathname,
          }}
          state={datas?.data}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: datas?.title,
              }}
            />
          </Link>
        </h5>
        <div className="binduz-er-meta-item">
          <div className="binduz-er-meta-author">
            {!datas?.isTopStories ? (
              <span>
                By <span>{datas?.author}</span>
              </span>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                onClick={datas?.onClickFun}
                // onClick={() => navigate("/details/10")}
              >
                <span>{"View Details>"}</span>
              </span>
            )}
          </div>
          <div className="binduz-er-meta-date">
            <span>
              <i className="fal fa-calendar-alt"></i> {datas?.date}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRowModule;
