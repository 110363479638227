import React from "react";
import img from "../../assets/images/azlogomessage.jpeg";


const ClassiFieldCart = ({ name, desc, index, onClick,image }) => (
  <div
    className="binduz-er-sidebar-add-box"
    style={{ backgroundImage: `url(${image||img})` }}
    onClick={onClick}
  >
  
    <div className="classifield-text" style={{color:'black'}}>
      <a > {name}</a>
    </div>
    <p style={{fontSize:'16px'}}>{desc}</p>

  </div>
);

export default ClassiFieldCart;
