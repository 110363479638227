import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import {
  formatDateToMMDDYYYY,
  getDatefromBackend,
  plainString,
} from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const ClassifieldDetails = ({ setselectedTab, data }) => {
  const navigate = useNavigate();

  const handleEdit = (id, index) => {
    // Handle edit logic here
    navigate(`/classifield-update/${id}`, { state: data[index] });
  };

  return (
    <div
      style={{
        marginTop: "10px",
        background: "none",
        padding: "10px",
        paddingLeft: "50px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <h2
          className="classifield-text "
          style={{
            height: "40px",
            display: "flex",
            textAlign: "end",
            marginTop: "20px",
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={() => navigate("/classifield-post")}
        >{`POST CLASSIFIED `}</h2>
      </div>
      {!_.isEmpty(data) ? (
        <div className="table-responsive">
          <Table striped bordered hover className="table-fixed custom-table">
            <thead className="thead-dark" style={{ backgroundColor: "#" }}>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Details</th>
                <th>Start Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.type}</td>
                  <td>{plainString(item?.description)}</td>

                  <td>{getDatefromBackend(item?.start_date)}</td>
                  <td>{item?.status === 1 ? "Active" : "Inactive"}</td>

                  <td>
                    {item?.type === "Premium" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="warning"
                          size="sm"
                          className="mr-2"
                          onClick={() => handleEdit(item?.id, index)}
                        >
                          <i className="fal fa-edit"></i>
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <h5 style={{ marginTop: "80px", textAlign: "center" }}>
          No Data Found
        </h5>
      )}
    </div>
  );
};

export default ClassifieldDetails;
