import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { getDatefromBackend, plainString } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const LocalBussinessDetails = ({ setselectedTab, data }) => {
  const navigate = useNavigate();

  const handleEdit = (id,index) => {
    // Handle edit logic here
    navigate(`/local-bussiness-update/${id}`, { state: data[index] });
  };

  return (
    <div
      style={{
        marginTop: "10px",
        background: "none",
        padding: "10px",
        paddingLeft: "50px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <h2
          className="classifield-text "
          style={{
            height: "40px",
            display: "flex",
            textAlign: "end",
            marginTop: "20px",
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={() => navigate("/local-bussiness-post")}
        >{`POST LOCAL BUSSINESS `}</h2>
      </div>
      {!_.isEmpty(data) ? (
        <div className="table-responsive">
          <Table striped bordered hover className="table-fixed custom-table">
            <thead className="thead-dark" style={{ backgroundColor: "#" }}>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Local Bussiness Information</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td >
                    {plainString(item?.description)}
                  </td>

                  <td>{item?.status === 1 ? "Active" : "Inactive"}</td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // gap: "10px",
                      }}
                    >
                     {item?.type === "Premium" && <Button
                        variant="warning"
                        size="sm"
                        className="mr-2"
                        onClick={() => handleEdit(item?.id, index)}
                      >
                       
                        <i className="fal fa-edit"></i>
                      </Button>}
                 
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <h5 style={{ marginTop: "80px", textAlign: "center" }}>
          No Data Found
        </h5>
      )}
    </div>
  );
};

export default LocalBussinessDetails;
