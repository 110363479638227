import React, { useContext, useEffect, useState } from "react";
import { getMsgData } from "../services/HomeServices";
import { toast } from "react-toastify";
import Loader from "./Loader";
import img from "../assets/images/azlogomessage.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { formatDateToMMDDYYYY } from "../lib/constant";
import _ from "lodash";
import "./index.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function MessageModule({
  toggle,
  search,
  isSearch = false,
  data,
  isBusiness = false,
  isMegaphone = false,
  isJob = false,
}) {
  const [messageData, setmessageData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);

  const navigate = useNavigate();
  const controlsLeft = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, inViewLeft]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  useEffect(() => {
    if (isSearch) {
      setmessageData(data);
    } else {
      getMessageData();
    }
  }, [toggle, search]);

  const getMessageData = async () => {
    setisLoading(true);

    await getMsgData(search, "", 1)
      .then((response) => {
        setisLoading(false);
        setmessageData(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  let moduleLendth = isSearch ? 4 : 5;

  const navigationActive = (item) => {
    if (isMegaphone) {
      navigate(`/details/${item?.id}`, {
        state: { ...item, isMegaphone: true },
      });
    } else if (isJob) {
      navigate(`/jobs-detail/${item?.id}`, {
        state: item,
      });
    } else {
      navigate(`/ticket-details/${item?.id}`, {
        state: item,
      });
    }
  };

  return (
    <div
      style={{
        paddingRight: "40px",
        minHeight: isLoading ? "500px" : "",
        paddingLeft: "40px",
      }}
    >
      {!isLoading ? (
        <motion.div
          ref={refLeft}
          initial="hidden"
          animate={controlsLeft}
          variants={boxVariantsLeft}
          className="row"
        >
          {!_.isEmpty(messageData) ? (
            <>
              {messageData?.slice(0, moduleLendth)?.map((item, index) => (
                <motion.div
                  whileHover={{ scale: 1.1 }} // Scale up the image on hover
                  transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
                  className="card event-card mt-4"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="card-body d-flex align-items-center p-0">
                    <div
                      className="col-lg-2"
                      style={{ textAlign: "center", marginRight: "4px" }}
                    >
                      <img
                        src={item?.image || img}
                        alt="AZIndia Logo"
                        className="event-card-img"
                      />
                    </div>
                    <div className="event-card-content col-lg-10">
                      <div className="binduz-er-title line-clump-2">
                        <Link
                          to={{
                            pathname: isMegaphone
                              ? `/details/${item?.id}`
                              : isJob
                              ? `/jobs-detail/${item?.id}`
                              : `/ticket-details/${item?.id}`,
                          }}
                          state={item}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.message,
                            }}
                          />
                        </Link>
                      </div>
                      <div
                        className="d-flex justify-content-between align-items-center event-card-footer"
                        style={{ paddingBottom: "10px" }}
                      >
                        {!isBusiness && (
                          <div className="binduz-er-meta-author">
                            <span
                              style={{ cursor: "pointer", color: "#e74d58" }}
                              onClick={() => {
                                navigationActive(item);
                              }}
                            >
                              {"View Details>"}
                            </span>
                          </div>
                        )}
                        <div className="binduz-er-meta-date">
                          <span>
                            <i
                              className="fal fa-calendar-alt"
                              style={{ marginRight: "7px" }}
                            ></i>
                            {formatDateToMMDDYYYY(new Date(item?.created_at))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </>
          ) : (
            <h4 style={{ minHeight: "150px", textAlign: "center" }}>
              No Data Found
            </h4>
          )}
        </motion.div>
      ) : (
        <div
          style={{ minHeight: "500px", display: "flex", alignItems: "center" }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
}

export default MessageModule;
