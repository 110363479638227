import React, { useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import LocalBussinessCard from "../../containers/PostCards/LocalBussinessCard";
import { Link, useNavigate } from "react-router-dom";
import {
  getLocalBussinessData,
  getbussinessCategoryList,
  getbussinessDataByCategory,
} from "../../services/HomeServices";
import { formatDate, plainString } from "../../lib/constant";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import img from "../../assets/images/azlogomessage.jpeg";
import _ from "lodash";

const MainSection = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [catSearch, setcatSearch] = useState("");
  const [catIsLoading, setcatIsLoading] = useState(false);
  const [bussinessCategoryList, setbussinessCategoryList] = useState([]);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [catId, setcatId] = useState();

  const [links, setlinks] = useState([]);

  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    if (!catId) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [search, page]);

  useEffect(() => {
    setTimeout(() => {
      getCategory();
    }, 1000);
  }, [catSearch]);

  useEffect(() => {
    if (catId) {
      setTimeout(() => {
        getDataByCategory();
      }, 1000);
    }
  }, [search, page, catId]);

  const getData = async () => {
    setisLoading(true);
    await getLocalBussinessData(search?.trim(), page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getCategory = async () => {
    setcatIsLoading(true);
    await getbussinessCategoryList(catSearch?.trim())
      .then((response) => {
        setcatIsLoading(false);
        setbussinessCategoryList(response?.data?.data);
      })
      .catch((error) => {
        setcatIsLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getDataByCategory = async () => {
    setisLoading(true);
    await getbussinessDataByCategory(catId, page, search)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">CATEGORIES</h3>
            </div>
            <div className="binduz-er-social-list">
              <div className="binduz-er-list">
                <Link
                  className="category-search"
                  style={{ marginBottom: "25px" }}
                >
                  <span>
                    <input
                      type="text"
                      value={catSearch}
                      onChange={(e) => setcatSearch(e?.target?.value)}
                      style={{ border: "none", color: "gray" }}
                      placeholder="Search Category..."
                    />
                  </span>
                  <i className="fa fa-search" onClick={() => getCategory()}></i>{" "}
                </Link>
                <div style={{ height: "700px", overflow: "scroll" }}>
                  {!catIsLoading ? (
                    <>
                      {bussinessCategoryList?.map((x) => (
                        <Link
                          className="category-search"
                          onClick={() => setcatId(x?.id)}
                        >
                          <span>
                            <span
                              style={{
                                cursor: "pointer",
                                color: `${catId === x?.id ? "#e74d58" : ""}`,
                              }}
                            >
                              {x?.name} - {x?.businesses_count || 0}
                            </span>
                          </span>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
         
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div
              className="binduz-er-top-news-title"
              style={{
                display: widthOfPage ? "flex" : "",
                justifyContent: "space-between",
              }}
            >
              <h3 className="binduz-er-title col-lg-5">
                <i
                  className="far fa-briefcase"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                LOCAL BUSSINESSES
              </h3>
              <h2
                className="classifield-text "
                style={{
                  height: "40px",
                  // marginLeft: "25px",
                  display: "flex",
                  // margin-right: 43px;
                  marginTop: "20px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => navigate("/local-bussiness-post")}
              >{`POST YOUR LOCAL BUSINESSES >>`}</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((item, index) => (
                      <div className="binduz-er-latest-news-item">
                        <div className="binduz-er-thumb">
                          <img
                            // src={require(`../../assets/images/classifield.png`).default}
                            src={item?.image || img}
                            alt=""
                          />
                        </div>
                        <div className="binduz-er-content">
                          {item?.name && (
                            <div className="classifield-text">
                              <span>{item?.name}</span>
                            </div>
                          )}
                          <div className="binduz-er-meta-date">
                            <span>
                              <i className="fal fa-calendar-alt"></i>{" "}
                              {formatDate(item?.start_date)}
                            </span>
                          </div>
                          <h5 className="binduz-er-title line-clump-2">
                            <Link>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              />
                            </Link>
                          </h5>

                          <h6 style={{ margin: "0px" }}>Contact Details:-</h6>
                          <p style={{ margin: "0px" }} className="line-clump-3">
                            {item.address}
                          </p>
                          <h6 style={{ margin: "0px" }}>Web:-</h6>
                          <a
                            className="line-clump-3"
                            style={{ cursor: "pointer" }}
                            href={item?.url}
                            target="_blank"
                          >
                            {item.url}
                          </a>
                        </div>
                      </div>
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Data found</h5>
                )}
              </>
            ) : (
              <div style={{ marginTop: "80px" }}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
