import React, { useEffect, useState } from "react";
import SliderCom from "../Helpers/SliderCom";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import "./index.css";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const imgArray = [
  img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img4,
  img3,
  img,
  img3,
  img2,
  img5,
  img3,
];

const ClassiFieldsAd = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);

  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
    if (inViewRight && !hasAnimatedRight) {
      controlsRight.start("visible");
      setHasAnimatedRight(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, controlsRight, inViewLeft, inViewRight]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  return (
    <section
      className="binduz-er-main-posts-area "
      // style={{ backgroundColor: "#0d6efd" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="binduz-er-top-news-title"
              style={{ paddingLeft: "40px" }}
            >
              <h3 className="binduz-er-title">
                {" "}
                EXPLORE YOUR CLASSIFIED IN ADVERTISING
              </h3>
            </div>
            <div
              style={{
                paddingLeft: "60px",
                paddingRight: "60px",
                paddingTop: "10px",
              }}
            >
              <motion.div
                ref={refRight}
                initial="hidden"
                animate={controlsRight}
                variants={boxVariantsRight}
                className="binduz-er-blog-related-post-slide"
              >
                <SliderCom settings={settings}>
                  {imgArray?.map((x) => (
                    <div
                      className="binduz-er-video-post binduz-er-recently-viewed-item"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="binduz-er-latest-news-item"
                        style={{ padding: "0px" }}
                      >
                        <div className="binduz-er-thumb classifield-ad-img ">
                          <img src={x} alt="" />
                        </div>
                      </div>
                    </div>
                  ))}
                </SliderCom>
              </motion.div>

              <motion.div
                ref={refLeft}
                initial="hidden"
                animate={controlsLeft}
                variants={boxVariantsLeft}
                className="binduz-er-blog-related-post-slide "
              >
                <SliderCom settings={settings}>
                  {imgArray?.reverse()?.map((x) => (
                    <div
                      className="binduz-er-video-post binduz-er-recently-viewed-item"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="binduz-er-latest-news-item"
                        style={{ padding: "0px" }}
                      >
                        <div className="binduz-er-thumb classifield-ad-img ">
                          <img src={x} alt="" />
                        </div>
                      </div>
                    </div>
                  ))}
                </SliderCom>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClassiFieldsAd;
